import React from 'react';
import { FormattedMessage } from 'react-intl';
import LogoPNG from '../../images/logo.png';
import InstagramPNG from '../../images/instagram.png';
import LocationPNG from '../../images/location.png';
import Button from '../Button';
import Wrapper from '../Wrapper';

import * as CSS from './Information.module.css';
import GoogleMapsPNG from '../../images/google-maps.png';

function Information() {
  return (
    <Wrapper className="bgInformation" id="location">
      <div className={CSS.wrapper}>
        <div className={CSS.title}>
          <img src={LogoPNG} alt="Logo" />
          <span>
            HOOKAH
            <br />
            VALENCIA
          </span>
        </div>

        <div className={CSS.label}><FormattedMessage id="informationAddressTitle" /></div>
        <div className={CSS.value}>Carrer de Luis Bolinches Compañ, 5, 46023 València, Valencia, Spain</div>

        <div className={CSS.label}><FormattedMessage id="informationWorkingHours" /></div>
        <div className={CSS.value}>
          <div className={CSS.workingHours}>
            <span><FormattedMessage id="monday" /></span>
            <span><FormattedMessage id="closed" /></span>
          </div>
          <div className={CSS.workingHours}>
            <span><FormattedMessage id="tuesday" /></span>
            <span><FormattedMessage id="closed" /></span>
          </div>
          <div className={CSS.workingHours}>
            <span><FormattedMessage id="wednesday" /></span>
            <span>19:00&nbsp;&mdash;&nbsp;02:00</span>
          </div>
          <div className={CSS.workingHours}>
            <span><FormattedMessage id="thursday" /></span>
            <span>19:00&nbsp;&mdash;&nbsp;03:00</span>
          </div>
          <div className={CSS.workingHours}>
            <span><FormattedMessage id="friday" /></span>
            <span>19:00&nbsp;&mdash;&nbsp;03:30</span>
          </div>
          <div className={CSS.workingHours}>
            <span><FormattedMessage id="saturday" /></span>
            <span>19:00&nbsp;&mdash;&nbsp;03:30</span>
          </div>
          <div className={CSS.workingHours}>
            <span><FormattedMessage id="sunday" /></span>
            <span>19:00&nbsp;&mdash;&nbsp;03:30</span>
          </div>
        </div>

        <div className={CSS.contacts}>
          <div>+34 644 19 93 10</div>
          <div>
            <a href="https://instagram.com/hookah_valencia/" target="_blank" rel="noreferrer">
              <img
                src={InstagramPNG}
                alt="Hookah Valencia Instagram"
              />
            </a>

            <a href="https://g.page/hookah_valencia" target="_blank" rel="noreferrer">
              <img
                src={GoogleMapsPNG}
                alt="Hookah Valencia Google Maps"
              />
            </a>
          </div>
        </div>

        <div className={CSS.callus}>
          <Button to="tel:+34644199310" type="ghost"><FormattedMessage id="callUs" /></Button>
        </div>

        <div className={CSS.location}>
          <img src={LocationPNG} alt="Hookah Base Valencia location" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Information;
