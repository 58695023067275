import React from 'react';
import classnames from 'classnames';
import Helmet from 'react-helmet';
import Information from '../../views/Information';
import * as GlobalCSS from '../../../styles.module.css';
import * as CSS from './index.module.css';

function IndexPage() {
  return (
    <>
      <Helmet
        title="Términos y conduciones de uso / Hookah Base - Shisha Lounge & Cocktails Bar"
      />

      <div className={CSS.wrapper}>
        <h1 className={classnames(GlobalCSS.textLogo, CSS.title)}>TÉRMINOS Y CONDICIONES DE USO</h1>

        <p>
          Proporcionamos servicios legales en nuevas tecnologías y ayudamos
          en el estudio eimplantación de Legaltech desde Baleares.
        </p>

        <p>
          ¿Sabes qué son los términos y condiciones de uso? ¿Comienzas con
          un negocio online y no tienes claro cómo redactarlas? No te la
          juegues y confía en verdaderos expertos en la materia. En Términos
          y Condiciones se encuentran los profesionales más completos a la
          hora de abarcar todo el aspecto jurídico que necesita tu ecommerce
          o página web.
        </p>

        <h2 className={CSS.h2}>Servicios</h2>

        <p>
          <strong>
            Prestamos servicios legales en materias como el comercio
            electrónico, la protección de datos, los contratos y licencias
            de software, la propiedad industrial e intelectual, entre otras.
            En resumen, podemos ayudar en cualquier cuestión jurídica
            relacionada con la tecnología.
          </strong>
        </p>

        <p>
          Desde la ciberseguridad, al marketing de influencia o la protección
          de datos en la publicidad, pasando por blockchain o asuntos
          internacionales como Brexit o la expansión comercial a China.
        </p>

        <p>
          A su vez, ayudamos en el estudio, recomendación e implementación
          de tecnología legal o Legaltech a despachos, departamentos
          jurídicos, facultades de Derecho o colegios de abogados.
        </p>

        <p>
          Consulta aquí nuestros Servicios o contáctanos vía email
          en: hola@terminosycondiciones.es
        </p>

        <h2 className={CSS.h2}>Blog</h2>

        <p>
          <strong>
            Escribimos con regularidad sobre el universo de las condiciones
            de uso, privacidad, publicidad y otras materias que trabajamos,
            así como materias relacionadas con la Legaltech (especialmente
            desde nuestra segunda marca, Legaltechies).
          </strong>
        </p>

        <p>
          <strong>Posts más recientes en Términos y Condiciones:</strong>
        </p>

        <ul>
          <li>Los términos y condiciones de la Dark Web</li>
          <li>El uso de música por los influencers y el efecto del Derecho</li>
          <li>Cómo controlar la obligación de publicidad de influencers mediante contratos inteligentes</li>
          <li>Twitch, streamers famosos y su relación contractual</li>
          <li>Así regulará Francia a los influencers niños o “Kidfluencers”</li>
        </ul>

        <p>
          También hablamos sobre materias o tecnologías que estamos estudiando
          o trabajando desde su vertiente legal como puedan ser la ciencia de
          datos, blockchain o el reglamento general de protección de datos, entre otros.
        </p>

        <p>
          Consulta aquí nuestro Blog.
        </p>

        <h2 className={CSS.h2}>Legal LAB</h2>

        <p>
          <strong>
            El Laboratorio Jurídico o Legal LAB es nuestra área de desarrollo
            e innovación. Aquí estudiamos tecnologías punteras desde una
            vertiente legal antes de que se conviertan en comunes o exploramos
            nuevas iniciativas y potenciales mercados.
          </strong>
        </p>

        <p>
          Si quieres consultar los diferentes proyectos realizados y nacidos
          en el Legal LAB desde su concepción, puedes consultar nuestra sección.
          Desde el Boletín de Términos y Condiciones, a la Videoguía Legal
          para Youtubers o el mapa de la Legaltech española.
        </p>

        <p>
          Consulta aquí nuestro Legal LAB.
        </p>

        <h2 className={CSS.h2}>Newsletter</h2>

        <p>
          Si te interesa el universo de la Legaltech, puedes suscribirte a
          nuestra newsletter sobre la materia, con las últimas novedades
          quincenales y en castellano sobre tendencias, herramientas, startups
          y más.
        </p>

        <h2 className={CSS.h2}>Contacto</h2>

        <p>
          <strong>
            Si quieres contactar con nosotros puedes hacerlo
            en: hola@terminosycondiciones.es y hola@legaltechies.es
          </strong>
        </p>

        <p>
          <strong>También puedes seguirnos en:</strong>
        </p>

        <ul>
          <li>Twitter (Términos y Condiciones)</li>
          <li>Instagram</li>
          <li>Youtube</li>
          <li>Facebook</li>
          <li>Twitter (Legaltechies)</li>
        </ul>

        <p>
          No merece la pena que asumas ningún riesgo y que puedas verte poco
          después en un problema legal que no te mereces. Si confías en nosotros,
          puedes tener la garantía del perfecto funcionamiento de tu tienda
          online, sin ningún tipo de miedo. Nuestro equipo, con Jorge Morell
          Ramos a la cabeza, te asegura la salud jurídica de tu negocio en
          Internet. Años de experiencia nos avalan. Somos líderes a la hora
          de prestar servicios jurídicos relacionados con el comercio
          electrónico. Te asesoramos de la mejor manera en procesos judiciales,
          fiscales y también con todos los aspectos legales que requieren
          los pagos online.
        </p>

        <p>
          Como propietario de la tienda online, eres el encargado de redactar
          los términos y condiciones de uso. Es importante que se haga de la
          manera correcta, ya que estás dejando claro la relación que existe
          entre consumidor y tu negocio, más concretamente con los productos
          o servicios que ofreces. Por eso no debes dejar nada al azar y en
          Términos y Condiciones estamos dispuestos a ofrecerte el asesoramiento
          más completo. No queremos que te veas envuelto en una sanción por
          una redacción incorrecta, así que deja que te escuchemos. Una redacción
          ambigua o poco clara tan solo puede perjudicarte.
        </p>

        <h2 className={CSS.h2}>Confía en nosotros tus dudas acerca de los términos y condiciones de uso</h2>

        <p>
          Somos expertos en textos legales ecommerce, pero abarcamos más
          campos como la propiedad intelectual e industrial, la publicidad,
          el marketing de influencia, en condiciones legales y contratos,
          ciberseguridad, blockchain, asuntos internacionales y legaltech.
        </p>

        <p>
          En Términos y Condiciones también podemos asesorarte con la LOPD
          de página web. Cualquier pequeño detalle poco cuidado o un
          incumplimiento de la misma puede darte un disgusto. Ofrecemos
          también servicio de asesoría en este aspecto y realizamos la
          solicitud de corrección o retirada de noticias y enlaces, así
          como asesoramiento legal en cuanto al análisis masivo de datos.
        </p>
      </div>
      <Information />
    </>
  );
}

export default IndexPage;
